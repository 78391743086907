<template>
    <v-menu 
        dense 
        open-delay="200" 
        :open-on-hover="isEditing && openOnHover && canOpen"
        :open-on-click="!openOnHover && canOpen"
        :open-on-focus="false"
        :close-on-content-click="false" :disabled="!isEditing">
        <template v-slot:activator="d">
            <slot name="activator" v-bind="{ on: d.on, attrs: d.attrs, select: select, itemText: itemText, textFilter: textFilter }">
                <span
                    :class="buttonClass"
                    :disabled="disabled"
                    :small="!xSmall"
                    :x-small="xSmall"
                    icon
                    v-bind="d.attrs"
                    v-on="d.on"
                    :title="nestVal(select, itemText, textFilter)">
                    <v-chip-group
                        :column="column"
                        :mandatory="mandatory"
                        :multiple="multiple"
                        :small="!xSmall"
                        :x-small="xSmall"
                        v-on="d.on"
                        v-bind="d.attrs">
                        <v-chip 
                            v-if="!isLengthyArray(select)"
                            :small="!xSmall"
                            :x-small="xSmall">
                            Select
                        </v-chip>
                        <v-chip
                            v-for="(item, index) in select"
                            :class="activeClass"
                            pill
                            :small="!xSmall"
                            :x-small="xSmall"
                            :key="index">
                            {{ nestVal(items[item], itemText, textFilter) }}
                        </v-chip>
                    </v-chip-group>
                </span>
            </slot>
        </template>

        <v-list>
            <v-list-item-group
                v-model="select"
                :multiple="multiple"
                @change="update">
                <template v-for="(f, i) in filteredItems">
                    <v-list-item :key="i" :active-class="activeClass">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-icon v-if="active" small>mdi-check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ nestVal(f, itemText, textFilter) }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Chips-Menu',
    data: function() {
        return {
            select: null,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: "primary"
        },
        buttonClass: {
            type: String,
            default: null
        },
        canOpen: {
            type: Boolean,
            default: true
        },
        canSelectNone: {
            type: Boolean,
            default: true
        },
        chips: {
            type: Boolean,
            default: false
        },
        column: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-timer'
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        mandatory: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        openOnHover: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: null //() => { return { }}
        },
        prefix: {
            type: String,
            default: null
        },
        prependIcon: {
            type: String,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        returnCSV: {
            type: Boolean,
            default: false
        },
        returnIndex: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        suffix: {
            type: String,
            default: null
        },
        textFilter: {
            type: String,
            default: null
        },
        value: null,
        xSmall: {
            type: Boolean,
            default: true
        }
    },
    async mounted() {
        this.load();
    },
    watch: {
        value: function(val) {
            if (val != this.select) {
                this.load();
            }
        }
    },
    computed: {
        filteredItems() {
            return this.onFilter ? this.onFilter(this.items) : this.items;
        }
    },
    methods: {
        load() {
            if (this.value == null) {
                return;
            }

            var d = this.returnCSV ? this.value.split(',') : this.value;
            var rList = [];
            for (let i = 0; i < this.items.length; i++) {
                if (this.isLengthyArray(d)) {
                    if (this.itemValue != null) {
                        if (d.some(x => x == this.getNestedValue(this.items[i], this.itemValue))) {
                            rList.push(i);
                        }
                    }
                    else {
                        if (d.some(x => x == this.filteredItems[i])) {
                            rList.push(i);
                        }
                    }
                    
                }
                else if (d != null && !this.multiple) {
                    if (this.itemValue != null) {
                        if (this.getNestedValue(d, this.itemValue) == this.getNestedValue(this.items[i], this.itemValue)) {
                            rList.push(i);
                        }
                    }
                    else {
                        if (d == this.items[i]) {
                            rList.push(i);
                        }
                    }
                }
            }
            
            if (this.multiple) {
                this.select = rList;
            }
            else {
                this.select = rList.length > 0 ? rList[0] : null;
            }
        },
        nestVal(item, path, filter) {
            var t = item;

            if (path != null) {
                t = this.getNestedValue(item, path);
            }
            
            if (filter != null) {
                return this.$options.filters[filter](t);
            }
            else {
                return t;
            }
        },
        update() {
            if (this.select == null) {
                this.$emit('input', null);
                this.$emit('change', null);
            }
            else {
                var v = this.select;

                if (this.multiple) {
                    if (this.returnIndex) {
                        v = this.select;
                    }
                    else {
                        var selectedItems = [];
                        for (let i = 0; i < this.select.length; i++) {
                            selectedItems.push(this.filteredItems[this.select[i]]);
                        }

                        if (this.returnObject) {
                            v = selectedItems;
                        }
                        else {
                            v = this.itemValue != null ? selectedItems.map(x => x[this.itemValue]) : selectedItems;
                        }
                        
                        if (this.returnCSV) {
                            v = v.toString();
                        }
                    }
                }
                else {
                    if (this.returnIndex) {
                        v = this.select;
                    }
                    else if (this.select != null) {
                        var a = this.filteredItems[this.select];
                        if (a != null) {
                            if (this.returnObject) {
                                v = a;
                            }
                            else {
                                v = this.itemValue != null ? this.getNestedValue(a, this.itemValue) : a;
                            }
                        }
                    }
                }

                this.$emit('input', v);
                this.$emit('change', v);
            }
        }
    }
}
</script>